.containerLogin {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  .containerLogin > form {
    width: 70%;
    border: 1px solid #dfdfdf;
    padding: 20px;
    border-radius: 5px;
    background: #fff;
  }
  pre {
    width: 70%;
    color: #fff;
    font-size: larger;
  }
  
  .loginPara {
    color: red;
  }
  .heading-sign{
    text-align: center;
  }
  .loginButton-2{
    margin-top: 20px;
  }
  .twowayLogin{    
    margin: 0 auto;
    text-align: center;
    position: relative; 
  }
  .or-para{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .terms{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .error{
    color: red;
    font-size: small;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }