.cards-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  min-height: 250px;
  width: 100%;
  padding: 20px;
}

.cards-wrap2 {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-left: 250px;
}

@media screen and (max-width: 1280px) {
  .cards-wrap2 {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-left: 150px;
  }
}
@media screen and (max-width: 1024px) {
  .cards-wrap2 {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .cards-wrap2 {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-left: 0px;
  }
}

.cards-wrap .card {
  width: 100%;
  border-radius: 20px;
  position: relative;
  max-width: 300px;
}

.cards-wrap .card .image {
  border-radius: 20px;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  min-height: 300px;
  width: 300px;
}

.cards-wrap .card .content {
  top: 0;
  left: 0;
  position: absolute;
  padding: 20px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
}

.cards-wrap .card .content .title {
  font-size: 1.5rem;

}

.cards-wrap .card .content .video-icon {
  position: absolute;
  top: 5px;
  right: -17px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 25px;
  height: 25px;
}

.cards-wrap .card .content .video-icon.small {
  width: 18px;
  height: 18px;
  right: -10px;
}

.cards-wrap .card .content .label-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.cards-wrap .card .content .label {
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #FCCC75;
}

.cards-wrap .card .content .label.light-blue {
  background-color: #4DC9D1;
}

.cards-wrap .card .content .label.royal-blue {
  background-color: #0082CD;
}

.cards-wrap .card .content .label.purple {
  background-color: #8D5EF2;
}

.cards-wrap .card .content .label.curved {
  border-radius: 20px;
}

.cards-wrap .card .content .sub-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
}

.cards-wrap .card .content .sub-content .labels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cards-wrap .card .content .sub-content .labels .label-wrap .video-icon {
  top: 0;
}

.video-link {
  text-decoration: none;
  color: #fff;
}

.categories-mediums {
  width: 25%;
  display: flex;
  justify-items: start;
}