.my-subscriptions {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .my-subscriptions h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .subscription-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    max-height: 300px;
  }
  .subscription-container{
    margin-top: 100px;
    margin-bottom: 150px;
  }

  .subscription-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .subscription {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 4px;
  }
  
  .subscription h3 {
    margin: 0;
  }
  
  .subscription p {
    margin: 5px 0;
  }
  
  .subscription button {
    background-color: #ff5c5c;
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
  }
  