.seperations {
  margin-bottom: 20px;
  /* border: 2px solid #333; */
  border-radius: 5px;
  padding: 10px;
}
.seperations:hover {
  background-color: #dcdada;
  border-color: #302f2f;
}

.heading {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.underTheHeading {
  font-size: 16px;
  margin-left: 18px;
  color: #555;
  margin-bottom: 10px;
}

.subheading {
  margin-left: 28px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  /* margin-bottom: 10px; */
}
.underTheSubHeading {
  font-size: 13px;
  margin-left: 30px;
  color: #555;
  line-height: 1;
}

/* Media query for screens with a width of 768 pixels and below */
@media screen and (max-width: 768px) {
  .main-privacy-policy {
    flex-direction: column;
  }

  .left-side,
  .right-side {
    width: 100% !important;
  }

  .privacy-policy-text2 {
    order: -1; /* Move the right-side content above the left-side content */
  }
}

.privacy-policy {
  width: 100%;
  height: auto;
  padding: 0;
  background-color: white;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-image: url('../bg-img.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/* .privacy-intro-container {
  max-width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px auto;
} */

.parentHeader {
  background-color: #333;
  color: #fff;
  text-align: center;
  font-size: 18px;
  padding: 20px;
}
.privacy-intro {
  width: 100%;
  font-weight: 700;
  margin: 20px;
  margin-top: 0;
  color: #333;
  /* margin: 0 auto; */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-around; */
  /* height: auto; */
  font-size: 17px;
  margin-bottom: 35px;
}

.left-side {
  /* flex: 1; */
  width: 50%;
}

.right-side {
  width: 50%;
  /* flex: 1; */
  /* display: flex;
  flex-direction: column; */
}

.privacy-policy-img {
  width: 100%;
  height: auto;
}

.privacy-policy-text {
  /* width: 90%; */
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: stretch;
}

.privacy-policy-text2 {
  /* width: 50%; */
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: stretch;
}
.privacy-policy-text2 p {
  color: rgb(13, 13, 13);
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 45px;
}

.main-privacy-policy {
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
  gap: 35px;
  height: auto;
}

.privacy-policy-text h1 {
  color: rgb(243, 22, 22);
  font-size: 50px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.privacy-policy-text p {
  color: rgb(13, 13, 13);
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 45px;
}

.btn-know {
  background: red;
  color: white;
  text-decoration: none;
  border: 1px solid transparent;
  font-weight: bold;
  padding: 10px 10px;
  border-radius: 10px;
}

/* .button{
    transition: .4s;
}
.button:hover{
    background: white;
    color: red;
    border: 1px solid;
} */
@media screen and (max-width: 768px) {
  .privacy-policy {
    width: 100%;
    padding: 0;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
  }
}
