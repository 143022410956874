video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  background-color: rgb(255, 255, 255);
}

.hero-container>h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

.hero-container>p {
  margin-top: 8px;
  color: rgb(217, 31, 31);
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-align: center;
}

.welcome-text {
  text-align: center;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.home-main-types {
  padding: 13px 24px;
  text-align: center;
}

.row-fluid-1 {
  text-align: center;
}

.btn-more {
  background: red;
  color: white;
  text-decoration: none;
  border: 1px solid transparent;
  font-weight: bold;
  padding: 15px 15px;
  border-radius: 10px;
}

.btn-more-div {
  text-align: center;
  margin-top: 1%;
}

.hero-h1 {
  color: rgb(87, 87, 219);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode',
    Geneva, Verdana, sans-serif;
  font-style: normal;
}

.mediums-container {
  width: 25%;
  display: flex;
  justify-items: start;
}

@media screen and (max-width: 960px) {
  .hero-container>h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}


@media screen and (max-width: 768px) {
  .hero-container>h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container>p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .mediums-container{
    width: 100%;
  }
}