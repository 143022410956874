

  .__main {
    max-width: 90%;
    margin: auto;
    background-color: #fff;
    min-height: 80vh;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    padding-left: 0;
    
  }
  
  



