.custom_search {
    /* width: "1683px";
    height: "256px";
    background: "#E7E7E7";
    margin-bottom: "50px"; */
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f1;
    text-align: center;
}

@media (max-width: 768px) {
    .custom_search {
        width: 90%;
        padding: 10px;
    }
}

@media screen and (max-width: 600px) {

    .dropdown_menu {
        width: 100%;
        top: 100%;
        display: block;
    }

}
.dropdown{
    margin-left: 25px;
    width: 100%;
    justify-items: start;
}
.dropdown_label_container{
    width: 15%;
    margin-right: 10px;
}

@media screen and (max-width: 600px) {

    .all-sub-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: space-around
    }
    .dropdown{
        margin-left: 0px;
    }
    .dropdown_label_container{
        width: 100%;
        margin-left: 0px;
    }
    /* .dropdown_container label{
        width: 100%;
    } */

}