.about {
    width: 100%;
    height: auto;
    padding: 0;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-image: url('../bg-img.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.left-side {
    /* flex: 1; */
    width: 50%;
}

.right-side {
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.about-img {
    width: 100%;
    height: auto;
}

.about-text {
    width: 90%;
}

.about-text2 {
    /* width: 50%; */
    display: flex;
    justify-content: end;
    align-items: end;
}
.about-text2 p {
    color: rgb(13, 13, 13);
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 45px;
}

.main-about {
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
    height: auto;
}

.about-text h1 {
    color: rgb(243, 22, 22);
    font-size: 50px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.about-text p {
    color: rgb(13, 13, 13);
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 45px;
}

.btn-know {
    background: red;
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 10px;
}

/* .button{
    transition: .4s;
}
.button:hover{
    background: white;
    color: red;
    border: 1px solid;
} */
@media screen and (max-width: 768px) {
    .about {
        width: 100%;
        padding: 0;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

    }


}