* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .btnn {
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    width: 230px;
    height: 47px;
    line-height: 47px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
    display: flex;
    padding: 0;
    transition: all 0.3s ease-in-out;
    
  }
  .btnn:hover {
    background-color: #4664ff;
    color: #fff;
    transform: scale(1.02);
  }
  .btnn i {
    flex: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 18px;
    border-right: 1px solid #ebe7fb;
  }
  .btnn span {
    flex: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ff6c6c;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fe3b3b;
  }