.navbar {
  background: white;
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: rgb(245, 55, 55);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo:hover {
  color: rgb(187, 149, 149);
  transition: all 0.2s ease-out;
}

.fa-book-open {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;

}

.nav-item {
  height: 20px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 50%;
  color: rgb(84, 83, 83);
}

.nav-links:hover {
  color: rgb(225, 29, 29);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: rgb(5, 5, 5);
}


.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    gap: 25px;
  }

  .nav-menu.active {
    background: #fbf8f8;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;

    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: rgb(230, 68, 68);
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: rgb(4, 0, 0);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 50%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: rgb(250, 5, 13);
    padding: 14px 20px;
    border: 1px solid rgb(9, 9, 9);
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.semma {
  position: absolute;
  top: 70px;
  background: rgb(71, 64, 64);
  opacity: 0;
  visibility: hidden;
  text-decoration: none;
}

#btn-signup {
  display: inline-block;
  cursor: pointer;
  background: linear-gradient(90deg, rgb(223, 210, 210) 0%, rgb(109, 95, 95) 100%);
  transition: .4s;
  background: white;
  color: red;
  border: 1px solid;
  border-radius: 6px;
}
#btn-signup:hover {
  background-color: #3c38f6;
  color: #ffffff;
}