.slider {
  position: absolute;
  height: 100vh;
  width: 100%;
  /* max-height: 1100px; */
  overflow: scroll;
}

.image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.right-arrow {
  position: absolute;
  top: 45%;
  right: 32px;
  font-size: 3rem;
  color: #ece1e1;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 45%;
  left: 32px;
  font-size: 3rem;
  color: #ece1e1;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  /* transform: scale(1.08); */
}

.get-started-btn {
  /* background: linear-gradient(90deg, rgb(223, 210, 210) 0%, rgb(109, 95, 95) 100%); */
  background-color: #e0e0e0;
  padding: 14px 20px;
  font-size: 0.9396rem;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  color: #000000de;
  cursor: pointer;
}

.get-started-btn:hover {
  color: #4183c4;
  /* color: #ff0000; */
}