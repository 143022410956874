.download-app {
    /* width: 100%;
    padding: 0;
    background-color: white;
    margin-top: 20px;
    background-color: rgb(251, 230, 237); */
    width: 100%;
    height: auto;
    padding: 0;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-image: url('../bg-img.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.download-app-img-container {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-app-img {
    height: 600px;
    width: 50%;
    border: solid;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.download-app-text {
    width: 50%;
}

.main-download-app {
    width: 1130px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.download-app-text h1 {
    color: rgb(243, 22, 22);
    font-size: 50px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.download-app-text p {
    color: rgb(13, 13, 13);
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 45px;
}

.btn-know {
    background: red;
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 10px;
}

/* button{
    transition: .4s;
}
button:hover{
    background: white;
    color: red;
    border: 1px solid;
} */
.stores {
    display: flex;
    justify-content: space-around;
    height: 100px;
}

.apple, .android {
    background-position: center;
    height: 44px;
    max-width: 150px;
    border: none;
    cursor: pointer;
  }

.apple {
    background-image: url('../appstore.png');
    background-size: cover;
    background-position: center;
    width: 27%;
    border-radius: 40px;
}

.android {
    background-image: url('../playstore.png');
    background-size: cover;
    background-position: center;
    width: 25%;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {
    .download-app-img {
        height: auto;
        width: 100%;
    }

    .stores {
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 100%;
    }

    .apple, .android {
        width: 80%;
        margin-bottom: 10px;
    }
}