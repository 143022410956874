.discussion {
    width: 100%;
    height: auto;
    padding: 0;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-image: url('../bg-img.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.discussion-img-container {
    height: 560px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.discussion-img {
    height: auto;
    /* height: 600px; */
    width: 100%;
}

.discussion-text {
    margin-left: 50px;
    width: 50%;
}

.main-discussion {
    width: 1130px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.discussion-text-h1 {
    color: rgb(243, 22, 22);
    font-size: 50px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

.discussion-text p {
    color: rgb(13, 13, 13);
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 45px;
}

.btn-know-container{
    width: 30%;
    align-content: end;
}

.btn-know {
    background: red;
    color: white;
    text-decoration: none;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 10px;
}

/* .button{
    transition: .4s;
}
.button:hover{
    background: white;
    color: red;
    border: 1px solid;
} */
@media screen and (max-width: 768px) {
    .discussion {
        width: 100%;
        padding: 0;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;

    }
    @media (max-width: 600px) {
        /* for screens smaller than 600px */
        .discussion-text-h1 {
          font-size: 1.8rem; /* smaller font size */
        }
      }


}