.first-row{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.box-contact-us{
    /* position: relative; */
    /* padding: 20px 0; */
    /* display: flex; */
    gap: 10px;
}
.icon{
    min-width:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.contact-section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
    background-color: rgb(245, 249, 251);
}
.contact-info{
    max-width: 500px;
    line-height: 65px;
    padding-left: 50px;

}
.contact-form{
    max-width: 700px;
    margin-right: 50px;
}
.contact-info, .contact-form{
    flex: 1;
   
}
.text-box{
    background: rgb(18, 18, 18);
    color: white;
    border: none;
    height: 50px;
    padding: 12px;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 12px;
    opacity: 0.8;
}
.text-box-white{
    border: solid;
    background: transparent;
    color: black;
    border: none;
    height: 50px;
    padding: 12px;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    /* margin-top: 20px; */
    margin-right: 12px;
    opacity: 0.8;
}
textarea{
    background: rgb(18, 18, 18);
    color: white;
    border: none;
    width: 96%;
    min-height: 200px;
    max-height: 400px;
    resize: vertical;
    padding: 12px;
    font-size: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    opacity: 0.8;
}
.send-btn{
    float: left;
    background: rgb(18, 18, 18);
    color: white;
    border: none;
    width: 120px;
    height: 40px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    border-radius: 5px;
    opacity: 0.8;
    margin-bottom: 20px;
    cursor: pointer;
}
.send-btn:hover{
    background: rgb(243, 9, 9);
}
@media screen and (max-width:950px){
    .contact-section{
        flex-direction: row;
    }
}